.upload-btn{
    background-color: rgb(226, 128, 0);
    border: 4px solid black;
    border-radius: 5px;
    color: rgb(0, 0, 0);
    font-weight: 900;
    font-size: medium;
}
.upload-btn:hover{
    background-color: rgb(184, 4, 255);
}