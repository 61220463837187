.header {
  font-size: 100px;
  font-family: "Honk", system-ui;
  margin-left: 20px;
}
.body {
  background-color: rgb(0, 0, 0);
}
.container {
  display: flex;
}
.modal_container {
  font-family: sans-serif;
  font-size: small;
}
.box-content {
  background-color: #fffcfc;
  border: 10px solid rgba(255, 174, 0, 0.678);
  width: 600px;
  text-align: center;
  align-items: center;
  padding: 10px;
}
.box-content p {
  color: black !important ;
}
.timer {
  font-size: 40px;
  display: flex;
  justify-content: center;
  background-color: black;
}
.box {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
/* progressBar */
.progressBar_container {
  font-size: 2px;
  display: flex;
  justify-content: center;
  margin-top: -5px;
}

/* main mouse image */
.mouse-image img {
  width: 600px;
}
/* heading and mouse image flex */

.header p {
  color: #fffdfd;
  font-size: large;
  font-weight: bolder;
  font-family: sans-serif;
}
/* about murine */
.about-box {
  display: flex;
}
.about-box img {
  width: 600px;
  opacity: 0; /* Initial state for animation */
  transition: opacity 2s ease-in-out, transform 1s ease-in-out;
  transform: translateX(100%);
}
.about-box.slide-in img {
  opacity: 1;
  transform: translateX(0); /* Slide in to its original position */
}

.about-text {
  position: relative;
  top: 50%;
  left: -100%; /* Start off-screen */
  opacity: 0; /* Initial state for animation */
  transition: left 2s ease-in-out, opacity 1s ease-in-out;
}

.about-box.slide-in .about-text {
  left: 0; /* Slide in from left */
  opacity: 1;
}

.about-box.slide-in h1,
.about-box.slide-in p {
  opacity: 1;
}

.about-box h1 {
  font-size: 50px;
  margin-top: 80px;
  opacity: 0; /* Initial state for animation */
  transition: opacity 1s ease-in-out;
  font-family: "Honk", system-ui;
}

.about-box p {
  font-size: large;
  font-weight: bolder;
  font-family: sans-serif;
  color: white;
  padding-right: 5px;
  opacity: 0; /* Initial state for animation */
  transition: opacity 1s ease-in-out;
}
.wallet-id {
  font-family: sans-serif;
  font-weight: 900;
}
.how-to-buy h1 {
  font-size: 50px;
  font-family: "Honk", system-ui;
}
.how-to-buy h3 {
  font-size: 40px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: bolder;
}
.how-to-buy p {
  font-size: 20px;
  font-weight: 600;
}

.how-to-buy {
  color: white;
  text-align: center;
  width: 600px;
  margin-bottom: 25px;
}
.how-to-buy-container {
  display: flex;
  justify-content: center;
}
.converter_box{
  margin-top: -50px;
}
.converter_box input{
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 700;
  width: 120px;
  border: 5px solid rgb(0, 0, 0);
  border-radius: 10px;
}
.converter_box input:hover{
  border-color: burlywood;
}
.converter_box label{
  font-family: "Honk";
  font-size: 30px;
  font-weight: 700;
  margin-left: 5px;
}
.converter_box p{
  margin-top: -15px;
}
.faq{
  color: white;
}
.faq h1{
  font-size: 50px;
  font-family: "Honk", system-ui;
  text-align: center;
}
.tokenomics{
  margin-top: 30px;
  margin-bottom: 30px;
}

.tokenomics h1{
  font-size: 50px;
  font-family: "Honk", system-ui;
  text-align: center;
}
.roadmap h1{
  font-size: 50px;
  font-family: "Honk", system-ui;
  text-align: center;
}
.carousel{
  width: 700px;
  margin: auto;
}

/* Tab view */
@media (max-width: 1024px) {
  .header h1 {
    font-size: 5rem;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .image-with-text img {
    margin-top: 200px;
  }
  .about-box img {
    margin-top: 120px;
  }
  .mouse-image img {
    display: none;
  }
  .about-box {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  .about-box img {
    width: 470px;
  }

  .about-text {
    margin-left: 20px;
  }
  .timer {
    
    font-size: xx-large;
  }
  .timer-container {
    margin-top: -200px;
  }
  .timer-container p {
    margin-top: 300px;
    text-wrap: nowrap;
    font-size: x-large;
  }
}
/* Phone View */

@media (max-width: 768px) {

  .carousel{
    width: 300px;
    margin: auto;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .header h1 {
    font-size: 4.3rem;
  }
  .box {
    margin-top: 50px;
    margin-right: 20px;
  }
  .header p {
    font-size: large;
    color: rgb(255, 255, 255);
    font-size: medium;
  }
  .image-with-text img {
    margin-top: -10px;
  }
  .mouse-image img {
    display: none;
  }
  .about-box {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
  .about-box img {
    width: 350px;
  }
  .about-text {
    margin-left: 20px;
  }
  .about-text h1 {
    font-size: 50px;
    font-family:"Anton SC", sans-serif ;

  }
  .about-box p {
    font-size: large;
    font-weight: bolder;
    font-family: sans-serif;
    color: rgb(255, 255, 255);
    padding-right: 5px;
    opacity: 0; /* Initial state for animation */
    transition: opacity 1s ease-in-out;
  }
  .image-with-text p {
    font-size: small;
    margin-top: 10px;
    text-wrap: nowrap;
    color: black;
  }
  .timer-container {
    margin-top: 100px;
  }
  .timer-container p {
    margin-top: 280px;
    text-wrap: nowrap;
    color: black;
  }
  .timer{
    font-family: "Anton SC", sans-serif;
    background: linear-gradient(45deg, #000000, #da0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .converter_box label{
    font-family: "Anton SC", sans-serif;
  }
  .mouse-image img {
    margin-top: -150px;
  }
  .how-to-buy h1{
    font-size: 50px;
    font-family:"Anton SC", sans-serif ;
  }
  .how-to-buy h3, .how-to-buy p{
    text-align: start;
    margin: 1rem;
    
  }
  .roadmap h1{
    font-family:"Anton SC", sans-serif ;
  }
  .tokenomics h1{
    font-family:"Anton SC", sans-serif ;
  }
  .faq h1{
    font-family:"Anton SC", sans-serif;
  }
}
@media only screen and (max-width: 768px) {
  .app {
    font-family: "Anton SC", sans-serif;
    background: linear-gradient(45deg, #f94c63, #ffcd38, #0cbaba, #7fcd91);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .black {
    font-family: "Anton SC", sans-serif;
    background: linear-gradient(45deg, #000000, #da0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

/* About animation keyframes */

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideOut {
  0% {
    transform: translateX(100%); /* Start from right */
    opacity: 0;
  }
  100% {
    transform: translateX(0); /* End at original position */
    opacity: 1;
  }
}