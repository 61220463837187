
.raised{
    color: whitesmoke;
}
.cont h1{
    font-size: xx-large;
}
  .cont{
    font-size: x-small;
    margin-bottom: 10px;
  }
  .progress-bg {
      border-radius: 10px;
      text-align: center;
      background-color: whitesmoke;
      -moz-box-shadow:    inset 0 0 10px #ccc;
      -webkit-box-shadow: inset 0 0 10px #ccc;
      box-shadow:         inset 0 0 10px #ccc;
  }
  
  .progress-bar {
    width: 30%;
      border-radius: 10px;
      text-align: center;
      float: left;
      /* fallback */ 
      background-color: #1c314a; 
      
      /* Safari 4-5, Chrome 1-9 */ 
      background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#1c314a), to(#27425f)); 
      
      /* Safari 5.1, Chrome 10+ */ 
      background: -webkit-linear-gradient(top, #1c314a, #27425f); 
      
      /* Firefox 3.6+ */ 
      background: -moz-linear-gradient(top, #1c314a, #27425f); 
      
      /* IE 10 */ 
      background: -ms-linear-gradient(top, #1c314a, #27425f); 
      
      /* Opera 11.10+ */ 
      background: -o-linear-gradient(top, #1c314a, #27425f);	
  }
  
  .progress-bg h3.goal, .progress-bg h3.raised {
      font-family: Arial,sans-serif;
    font-size: 2em;
    font-weight: 600;
      line-height: 78px;
      margin: 0;
      padding: 0;
      text-align: center;
      display: inline;
  }
  
  
  .progress-bg h3.raised {
      color: #fff;
      font-size: large;
  }
  
  .progress-bg h3.goal {
      color: #b2b2b2;
    text-align: center;
  }
  
  body .progress-bg h3.raised {
      -webkit-animation: fadein 4s; /* Safari and Chrome */
         -moz-animation: fadein 4s; /* Firefox */
          -ms-animation: fadein 4s; /* Internet Explorer */
           -o-animation: fadein 4s; /* Opera */
              animation: fadein 4s;
  }
  
  @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
  
  /* Firefox */
  @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
  
  /* Safari and Chrome */
  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
  
  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }​
  
  /* Opera */
  @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }​
  
  .progress-bg h3.goal {
      float: right;
      display: inline;
      padding: 0 25px 0 0;
    text-align: center;
  }
  @-webkit-keyframes progress-bar {
      from { width: 0%; }
      to { width: 50%; }
  }
  
  @-moz-keyframes progress-bar {
      from { width: 0%; }
      to { width: 50%; }
  }
  
  @-o-keyframes progress-bar {
      from { width: 0%; }
      to { width: 50%; }
  }
  
  @keyframes progress-bar {
      from { width: 0%; }
      to { width: 50%; }
  }
  
  @media (max-width: 768px){
    .cont h1{
        font-family: "Anton SC", sans-serif;
    }
  }