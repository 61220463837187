.top {
  font-family: "Honk", system-ui;
  font-size: 20px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  background: #f5e022d0; 
}
.logo img {
  width: 100px;
  height: 100px;
  background-size: cover;
}

li {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

@media (max-width: 768px) {
  .top button {
    padding: 5px;
    margin-top: 0.5rem;
    font-family: "Anton SC", sans-serif;
  }
  .logo img {
    width: 80px;
    height: 100px;
    background-size: cover;
  }
  .top a{
    white-space:nowrap;
    font-family: "Anton SC", sans-serif;
  }
  .top {
    font-family: "Honk", system-ui;
    font-size: 15px;
    height: 75px;

  }
  .top li{
    margin: 0;
    column-gap: 10px;
  }
  
}
