@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Honk';
  src: url('../public/asset/Honk-Regular-VariableFont_MORF\,SHLN.ttf') format('truetype'),
  url('../public/asset/honk-regular-variablefont_morfshln-webfont.woff') format('woff'),
  url('../public/asset/honk-regular-variablefont_morfshln-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
    font-variation-settings:
    "MORF" 15,
    "SHLN" 50;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding:0;
}
html {
  scroll-behavior: smooth;
}
.cl{
  font-size: large;
  color: red;   
}
/* Define the scrollbar width and height */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* The scrollbar track (background) */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* The scrollbar thumb (handle) */
::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #6a11cb, #2575fc); 
  border-radius: 10px;
}

/* The scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #6a11cb, #2575fc);
}