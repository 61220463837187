.faq_box span{
    color: white;
    font-weight: 900;
    font-size: x-large;
}
.faq_box p{
    color: white;
    font-weight: 700;
    font-size: larger;
}
.faq_box button:hover{
    background-color: aquamarine;
    
}