.refer_box span{
    color: rgb(0, 0, 0);
    font-weight: 900;
    font-size: medium;
}
.refer_box p{
    color: rgba(17, 1, 1, 0.801);
    font-weight: 700;
    font-size: larger;
}
.refer_box button:hover{
    background-color: rgb(184, 4, 255); 
}
.refer_box button{
    background-color: rgb(255, 191, 108);
    border: 4px solid black;
    border-radius: 5px;
    height: 30px;
}
.refer_box{
    margin-top: 1rem;
}